<template>
	<div>

		<table class="table">
			<thead>
			<tr>
				<th>NAME</th>
				<th>CODE</th>
				<th>ISO</th>
				<th>STATUS</th>
				<th>IMAGE</th>
        <th>DEFAULT</th>
			</tr>
			</thead>
			<tbody>

			<tr v-for="language in languages"
					:key="language.Id">
				<td>
					<router-link :to="{name: 'languages-id', params: {id: language.Id}}">
						{{ language.Name }}
					</router-link>
				</td>
				<td>
					{{ language.Code }}
				</td>
				<td>
					{{ language.Iso }}
				</td>
				<td>
					<i class="fa fa-circle" :style="{'color': language.Status ? '#2DCC71' : 'Red'}"></i>
				</td>
				<td>
		  	<img v-if="language.Img" class="img-fluid rounded" :src="language.Img" :alt="language.Name" :title="language.Name"
			   style="max-width: 48px">
				</td>
        <td>
          <i class="fa fa-circle" :style="{'color': language.DefaultForTranslation ? '#2DCC71' : 'Red'}"></i>
        </td>
			</tr>
			</tbody>
		</table>

				<router-link class="btn btn-primary" :to="{name: 'languages-new'}">New Language</router-link>

		<div class="my-5"></div>

	</div>
</template>

<script>
import api from '@/api'

export default {
	name: "index",
	data: () => ({list: [], loading: false}),
	async mounted() {
		this.list = await api.Localizations.Languages.getList()
	},
	computed: {
		languages() {
			return this.list.slice()
		}
	}
}
</script>

<style scoped lang="scss">
@import "@/assets/style/_palette";

@keyframes blink-animation {
	to {
		visibility: hidden;
	}
}

.games-grid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(128px, 1fr));
	grid-gap: 10px;

	.game-item {
		position: relative;
		border-radius: 12px;

		&:after {
			position: absolute;
			top: 10px;
			right: 10px;
			background-color: red;
			content: '';
			width: 10px;
			height: 10px;
			border-radius: 5px;
		}

		&.enabled:after {
			background-color: $color-primary;
			box-shadow: 0 0 0 2px darken($color-primary, 40%);
		}

		&.disabled:after {
			background-color: red;
			box-shadow: 0 0 0 2px darken(red, 40%);
			animation: blink-animation 1s steps(5, start) infinite;
		}
	}
}

.table th, .table td {
	vertical-align: middle;
}
</style>
